var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"modal-header"},[_c('IconButton',{staticClass:"index-100",attrs:{"van-button":{ background: 'rgba(14, 16, 18, 0.16)' },"van-icon":{ name: 'arrow-left', size: 20, color: '#0E1012' }},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('h3',{staticClass:"modal-title"},[_vm._v("Общая лента отчетов")])],1),_c('DealSwitch',{attrs:{"data":[
      {
        title: _vm.$t('global.myDeals'),
        value: 'my',
      },
      {
        title: _vm.$t('global.all'),
        value: 'all',
      },
    ]},on:{"onSwitch":_vm.getList},model:{value:(_vm.params.type),callback:function ($$v) {_vm.$set(_vm.params, "type", $$v)},expression:"params.type"}}),(_vm.reportList.length)?[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"","loading-text":_vm.$t('loading')},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.reportList),function(item,key){return _c('ReportItem',{key:key,attrs:{"item":item}})}),1)]:_c('div',{staticClass:"empty-task-list"},[_vm._v("Нету отчетов")]),_c('Button',{staticClass:"btn makeReport",attrs:{"button":{
      title: _vm.$t('taskItem.addReport'),
      color: 'rgba(14, 16, 18, 0.88)',
      borderRadius: '100px',
      backgroundColor: '#1D1B20',
      color: '#fff',
      height: '40px',
      fontSize: '14px',
      paddingX: '15px',
      icon: 'add-white.svg',
      iconPosition: 'left',
    }},nativeOn:{"click":function($event){return _vm.goToMakeReport()}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }