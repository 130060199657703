<template>
  <div class="container">
    <div class="modal-header">
      <IconButton
        class="index-100"
        :van-button="{ background: 'rgba(14, 16, 18, 0.16)' }"
        :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }"
        @click="$router.go(-1)"
      />
      <h3 class="modal-title">Общая лента отчетов</h3>
    </div>
    <DealSwitch
      v-model="params.type"
      :data="[
        {
          title: $t('global.myDeals'),
          value: 'my',
        },
        {
          title: $t('global.all'),
          value: 'all',
        },
      ]"
      @onSwitch="getList"
    />
    <!-- {{ params.type }} -->
    <template v-if="reportList.length">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        :loading-text="$t('loading')"
        @load="onLoad"
      >
        <ReportItem v-for="(item, key) in reportList" :key="key" :item="item" />
      </van-list>
    </template>
    <div v-else class="empty-task-list">Нету отчетов</div>
    <Button
      class="btn makeReport"
      @click.native="goToMakeReport()"
      :button="{
        title: $t('taskItem.addReport'),
        color: 'rgba(14, 16, 18, 0.88)',
        borderRadius: '100px',
        backgroundColor: '#1D1B20',
        color: '#fff',
        height: '40px',
        fontSize: '14px',
        paddingX: '15px',
        icon: 'add-white.svg',
        iconPosition: 'left',
      }"
    />
  </div>
  <!-- 
      @updateReportlistEmit="getList()"

   -->
</template>

<script>
import ApiReports from "@/api/reports";

import { mapGetters } from "vuex";

import moment from "moment";
import IconButton from "@/components/Global/IconButton.vue";
import Button from "@/components/Global/Button.vue";
import ReportItem from "@/components/Task/ReportItem";
import DealSwitch from "@/components/Catalog/DealSwitch";

export default {
  data() {
    return {
      loading: false,
      isLoading: true,
      finished: false,
      reportList: [],
      params: {
        limit: 10,
        offset: 0,
        type: "my",
      },
    };
  },
  computed: {
    ...mapGetters("User", ["selfUser"]),
  },
  components: {
    IconButton,
    Button,
    ReportItem,
    DealSwitch,
  },
  methods: {
    goToMakeReport() {
      this.$router.push("/reports/add");
    },
    onLoad() {
      // this.loading = true;
      // if (this.reportList.length >= 9) {
      this.params.offset += 10;

      ApiReports.getReports(this.params).then((responce) => {
        this.reportList.push(...responce.data);
        this.loading = false;
        if (responce.data.length < 1) {
          this.finished = true;
        }
      });
      // }
    },
    getList() {
      ApiReports.getReports(this.params).then((responce) => {
        this.reportList = responce.data;

        console.log(this.params);
      });
    },
  },
  mounted() {
    moment.locale("RU");
    this.getList();
  },
  computed: {
    ...mapGetters("User", ["selfUser"]),
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-bottom: 24px;

  .modal-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Prata";
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
  }
}

.makeReport {
  position: fixed;
  bottom: 15px;
  max-width: 968px;
  width: calc(100% - 24px);
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}
</style>
