<template>
	<div class="deal-switch">
		<button v-for="item in data" :key="item.value" class="switch-button" :class="{ active: selectedValue === item.value }" @click="selectButton(item.value)">{{ item.title }}</button>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			selectedValue: null
		};
	},
	methods: {
		selectButton(value) {
			this.selectedValue = value;
			this.$emit("input", value);
			this.$emit("onSwitch");
		}
	},
	mounted() {
		if (this.data[0]) {
			this.selectedValue = this.data[0].value;
		}
	}
};
</script>

<style lang="scss" scoped>
.deal-switch {
	display: flex;
	margin-bottom: 16px;
	padding: 4px;
	border: 1px solid #e5e7e1;
	border-radius: 100px;

	.switch-button {
		width: 100%;
		padding: 12px;
		font-size: 16px;
		background: transparent;
		border: none;
		border-radius: 100px;

		&.active {
			background: #e5e7e1;
		}
	}
}
</style>
